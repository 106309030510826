<template>
  <v-container>
    <v-form id="form" ref="form">
      <v-card outlined>
        <v-card-text class="pa-10">
          <h1 class="text-center mb-8 black--text">المناسبات</h1>
          <v-text-field
            v-model="log.phone"
            :rules="[(v) => !!v || 'حقل مطلوب']"
            outlined
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              رقم الهاتف
            </template>
          </v-text-field>
          <!--/ phone number -->
          <v-text-field
            outlined
            :rules="[(v) => !!v || 'حقل مطلوب']"
            v-model="log.password"
            :type="!visibility ? 'password' : 'text'"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              كلمة مرور المناسبة
            </template>
            <template slot="append">
              <v-btn @click="visibility = !visibility" small icon>
                <v-icon color="grey lighten-1">
                  {{ visibility ? "visibility" : "visibility_off" }}
                  <!--/ visiblility of password -->
                </v-icon>
              </v-btn>
            </template>
            <!--/ template -->
          </v-text-field>
          <!--/ password -->

          <v-btn
            @click="check_occasion()"
            color="primary"
            height="56"
            width="100%"
            :disabled="log_loading"
            :loading="log_loading"
            >دخول</v-btn
          >
        </v-card-text>
      </v-card>

      <v-snackbar width="auto" right v-model="snackbar.prop">
        {{ snackbar.err ? snackbar.errText : snackbar.succText }}
        <template v-slot:action="{ attrs }">
          <v-icon
            :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
            v-bind="attrs"
            >{{ snackbar.err ? "highlight_off" : "done" }}</v-icon
          >
        </template>
      </v-snackbar>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "check_occasion",
  data() {
    return {
      log: {
        phone: "123456",
        password: "123456",
      },
      log_loading: false,
      visibility: false,
      snackbar: {
        prop: false,
        err: false,
        errColor: "red lighteen-2",
        succColor: "success",
        errText: "",
        succText: "تم التسجيل بنجاح",
      },
    };
  },
  methods: {
    check_occasion() {
      if (this.$refs.form.validate() && this.log_loading == false) {
        this.log_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "clients/check_occasion",
              method: "post",
            },
            data: this.log,
          })
          .then((res) => {
            console.log(res);
            this.snackbar.prop = true;
            this.snackbar.err = false;
            this.$store.commit("update_occasion", res.data.data);
            this.$nextTick(() => {
              this.$router.push("/occasion");
            });
          })
          .catch((err) => {
            if (
              err.response.data.data == "client_not_found" ||
              err.response.data.data == "occasion_not_found"
            ) {
              this.snackbar.prop = true;
              this.snackbar.err = true;
              this.snackbar.errText = 'لم يتم العثور علي المناسبة';
            } else if(err.response.data.data == 'expired') {
              this.snackbar.prop = true;
              this.snackbar.err = true;
              this.snackbar.errText ="مناسبة منتهية";
            }
            this.$store.commit("clear_occasion");
            this.log_loading = false;

          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#form {
  margin: 100px auto 0;
  width: 500px;
}
</style>
